import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: "",
  }),
  tagTypes: ['BoatAction'],
  endpoints: (builder) => ({}),
}
)


