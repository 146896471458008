import { combineReducers, configureStore } from "@reduxjs/toolkit";
import Categories from "./reducers/Categories";

import Tickets from "./reducers/Tickets";
import Users from "./reducers/Users";
import Auth from "./reducers/Auth";

import { persistStore } from "redux-persist";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist/es/constants";
import Protocole from "./reducers/Protocole";
import Boats from "./reducers/Boats";
import Spots from "./reducers/Spots";
import Ports from "./reducers/Ports";
import Attachments from "./reducers/Attachments";
import Debug from "./reducers/Debug";
import { createLogger } from "redux-logger";
import Dockwalk from "./reducers/Dockwalk";
import Crane from "./reducers/Crane";
import TechnicalArea from "./reducers/TechnicalArea.reducer";
import { dockwalkApi } from "./api/dockwalk.api";
import { apiSlice } from "./api/api";

export interface ApiError {
  status: number;
}

const persistConfig = {
  key: "root",
  storage: localStorage,
  whitelist: ["auth", "categories", "protocole", "users", "ports"],
};

const loggerMiddleware = createLogger();

const reducers = combineReducers({
  tickets: Tickets,
  users: Users,
  categories: Categories,
  auth: Auth,
  protocole: Protocole,
  boats: Boats,
  dockWalk: Dockwalk,
  crane: Crane,
  spots: Spots,
  ports: Ports,
  attachments: Attachments,
  technicalArea: TechnicalArea,
  debug: Debug,
  [apiSlice.reducerPath]: apiSlice.reducer,
  // [ticketApi.reducerPath]: ticketApi.reducer,
  [dockwalkApi.reducerPath]: dockwalkApi.reducer,
});

// const persistedReducers = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware as any)
      .concat(dockwalkApi.middleware as any)
  // .concat(loggerMiddleware),
});

export const persistor = persistStore(store);

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
