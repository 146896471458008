import { FC, useState } from "react";
import { useAppSelector } from "../../../core/store/hooks";
import { getFirstLvlOnly } from "../../../core/store/selectors/categories";
import { TabFilter } from "../../UI/tabs/TabFilter";
import BtnCheckbox from "../../UI/buttons/BtnCheckbox";
import { useTranslation } from "react-i18next";
import { IonButton, IonContent, IonHeader, IonIcon, IonModal } from "@ionic/react";
import { add, closeSharp } from "ionicons/icons";

type CategorySelectProps = {
  selectedCategory: string | null
  onChange: (categoryId: string) => void
}
export const CategorySelect: FC<CategorySelectProps> = ({ selectedCategory, onChange }) => {
  const { t } = useTranslation()
  const ticketConfig = useAppSelector(state => state.tickets.config)

  const gategoriesAllowedToCreate = ticketConfig?.ticket_categories.filter(c => c.allow_creation_agent_app)

  const parentCategories = ticketConfig?.ticket_categories.filter(c => {
    const hasChildren = gategoriesAllowedToCreate?.some(cc => cc.parent_category === c.id && cc.allow_creation_agent_app)
    return !c.parent_category && hasChildren
  })

  const [selectedParentCategory, setSelectedParentCategory] = useState("")

  const defaultSelected = (parentCategories && parentCategories[0].id) || null

  const fullSelectedCategory = gategoriesAllowedToCreate?.find(c => c.id === selectedCategory)
  const fullSelectedParentCategory = parentCategories && parentCategories.find(c => c.id === (selectedParentCategory || defaultSelected))

  const listForCategorySelected = gategoriesAllowedToCreate ? gategoriesAllowedToCreate?.filter(c => c.parent_category === (selectedParentCategory || defaultSelected)) : []
  const listSplit = listForCategorySelected.length > 6 ? 5 : listForCategorySelected.length

  const [openMore, setOpenMore] = useState(false)

  return <div className="w-full">
    {
      parentCategories && parentCategories.length > 1 && <div className=" flex flex-wrap gap-3 mb-4">
        {
          parentCategories?.map(c => <TabFilter selected={selectedParentCategory ? selectedParentCategory === c.id : defaultSelected === c.id} onClick={() => {
            setSelectedParentCategory(c.id)
            // auto select first children if only one children
            // const children = gategoriesAllowedToCreate?.filter(cat => cat.parent_category === c.id)
            // if (children && children.length === 1) {
            //   onChange(children[0].id)
            // }
          }} count={c.label} />)
        }
      </div>

    }


    <div className="grid grid-cols-3 gap-2 w-full">
      {
        listForCategorySelected.slice(0, listSplit).map((cat) => <BtnCheckbox
          className=""
          iconfile={{
            selected: cat.icon_url,
            default: cat.icon_url,
          }}
          key={cat.id}
          onClick={() => {
            onChange(cat.id)
          }}
          selected={selectedCategory === cat.id}
          label={cat.label}
        />)
      }
      {
        listSplit < listForCategorySelected.length &&
        <button
          onClick={() => setOpenMore(true)}
          className=" border border-solid border-SECONDARY shadow-xl bg-white w-11 rounded-xl flex justify-center items-center">
          <IonIcon className="text-2xl" color="secondary" icon={add} />
        </button>
      }
    </div>

    <IonModal
      style={{
        "--max-width": "200px",
        "--min-width": "50vw",
        "--min-height": "fit-content",
        "--max-height": "80vh",
        "--height": "fit-content",
      }}
      isOpen={openMore}
      onIonModalDidDismiss={() => {
        setOpenMore(false)
      }}
    >
      <IonHeader className="flex items-center justify-between py-2 pl-3 border-b border-b-GREY shadow-md mb-3">
        <span className="font-bold">{fullSelectedParentCategory?.label}</span>
        <IonButton
          fill="clear"
          onClick={() => {
            setOpenMore(false)
          }}>
          <IonIcon className="text-2xl" color="secondary" icon={closeSharp} />
        </IonButton>
      </IonHeader>
      <div className="h-fit">
        <div className="mx-2 mb-6">
          {
            listForCategorySelected.slice(listSplit).map((cat) => <BtnCheckbox
              className=""
              iconfile={{
                selected: cat.icon_url,
                default: cat.icon_url,
              }}
              key={cat.id}
              onClick={() => {
                onChange(cat.id)
              }}
              selected={selectedCategory === cat.id}
              label={cat.label}
            />)
          }

        </div>
      </div>
    </IonModal>
    {/* && fullSelectedCategory.parent_category !== fullSelectedParentCategory?.id */}
    {fullSelectedCategory &&

      <div className="bg-white shadow-md px-2 py-1 rounded text-FONT_DARK text-sm mt-3 flex gap-3 items-center">
        <img
          alt="ico categ"
          style={{ filter: "invert(0.7) sepia(1) saturate(0)" }}
          className={"w-6 h-6"}
          src={fullSelectedCategory.icon_url}
        />
        <div>
          <span className="text-xs block">{t('ticket.category.choosen')}</span>
          <span className="font-bold">{parentCategories?.find(c => c.id === fullSelectedCategory.parent_category)?.label || ""} - {fullSelectedCategory?.label}</span>
        </div>
      </div>
    }

  </div>
}