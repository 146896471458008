import { useEffect, useState } from "react";
import { useAppSelector } from "../../../core/store/hooks";
import AddBtn from "../../UI/buttons/AddBtn";
import FieldItem from "../../UI/form/FieldItem";
import SelectList from "../../UI/form/SelectList";
import ModalEdit from "../../UI/ModalEdit";
import Avatar from "../../UI/users/Avatar";
import defaultAvatar from "../../../assets/icons/user.png";
import { IAssignee } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { IUserAgent } from "@wattsonelements/front-fdk/dist/models/User.models";
import { useTranslation } from "react-i18next";
import icon from "../../../assets/icons/ui/assignee.png"

export default function TicketAssignee({
  value,
  update,
}: {
  value: any;
  update: Function;
}) {
  const { t } = useTranslation()
  const [assignee, setAssignee] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const { all: agents, current: user } = useAppSelector((state) => state.users);
  const [sortedAgents, setSortedAgents] = useState<IUserAgent[]>([])

  useEffect(() => {
    if (value) {
      setAssignee(value);
    }
  }, [value]);

  useEffect(() => {
    if (!agents || agents.length === 0) return
    if (!value || value.length === 0) {
      setSortedAgents(agents)
      return
    }
    const flatAssignees = value.map((v: IAssignee) => v.id)
    const tmp = [...agents].sort((u1: IUserAgent, u2: IUserAgent) => {
      const included = flatAssignees.includes(u1.id)
      if (included) {
        return -1
      }
      if (!included) {
        return 1
      }
      return 0;
    })
    setSortedAgents(tmp)
  }, [agents, value])

  const assigneeAvatar = (agentId: string) => {
    const user = agents.filter((agent) => agent.id === agentId);
    if (user && user.length > 0) {
      return user[0].picture;
    }
    return defaultAvatar;
  };

  const updateAssignee = (agent: IAssignee, add: boolean, value: IAssignee[]) => {
    if (add) {
      return [...value, { id: agent.id }];
    }
    // need an explanation, dear past self
    return value.filter((user) => user.id !== agent.id);
  };

  const onSave = (isSave: boolean) => {
    if (!isSave) {
      setAssignee(value);
    } else {
      update(assignee);
    }
  };
  return (
    <FieldItem
      filled={assignee.length > 0}
      onClick={() => {
        if (user?.ticket_can_update_assignees) {
          setOpen(!open)
        }

      }}
      field={{
        icon,
        title: t("ticket.formFields.assignees")
      }}
    >
      <>

        <ModalEdit
          save={onSave}
          title={t("ticket.formFields.assignees")}
          open={open}
          setOpen={setOpen}
        >
          {assignee && (
            <SelectList
              isSelected={(item: IUserAgent) => {
                const found = assignee.find((val: any) => {
                  return val.id === item.id;
                });
                if (found) return true;
                return false;
              }}
              getIcon={(item: IUserAgent) => {
                return item.picture
              }}
              transformText={(item: IUserAgent) =>
                `${item.first_name} ${item.last_name}`
              }
              multiple
              data={sortedAgents}
              setdata={(agent: IAssignee, add: boolean) =>
                setAssignee(updateAssignee(agent, add, assignee))
              }
            ></SelectList>
          )}
        </ModalEdit>
        {assignee.length === 0 && user?.ticket_can_update_assignees && (
          <AddBtn
            icon={icon}
            onClick={() => {
              setOpen(true)
            }}
          />
        )}
        {
          assignee.length === 0 && !user?.ticket_can_update_assignees &&
          <div className="flex italic text-slate-500 w-full justify-center p-4">
            {t('ticket.formFields.assigneAgetNotAllowed')}
          </div>
        }
        {assignee.length > 0 && (
          // AvatarList
          <div className="flex flex-1 w-full overflow-auto gap-1">
            {assignee.map((agent) => (
              <Avatar
                src={assigneeAvatar(agent.id)}
                key={agent.id}
              />
            ))}
          </div>
        )}
      </>
    </FieldItem>
  );
}
