import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fakeApiCall } from "../../../utils/FakeApi";
// import { MOCK_ticketslist } from "../../../_faker/tickets.mock";
import FDK from "@wattsonelements/front-fdk";
import { ITicket, ITicketMessage } from "@wattsonelements/front-fdk/dist/models/Ticket.models";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";
import { TicketConfig } from "../../models/Ticket";
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from "../store";
import { ticketApi } from "../api/ticket.api";

export interface TicketsState {
  list: ITicket[];
  listOpens: ITicket[];
  listClosed: ITicket[];
  currentList: "listClosed" | "listOpens";
  filters: {
    text: string;
    category: string;
    users: string[];
    status: { [key: string]: boolean };
  };
  selected: ITicket | null;
  loading: boolean;
  toCreate: Partial<ITicket> | null;
  listLoading: boolean;
  firstLoading: boolean;
  status: number;
  error: any;
  config: TicketConfig | null;
}

const initialState = {
  list: [],
  listOpens: [],
  listClosed: [],
  selected: null,
  loading: false,
  toCreate: null,
  currentList: "listOpens",
  listLoading: true,
  firstLoading: true,
  config: null,
  filters: {
    text: "",
    category: "",
    users: [],
    status: {
      [FDK.Constants.TicketConstants.TicketStatus.ONGOING]: true,
      [FDK.Constants.TicketConstants.TicketStatus.OPEN]: true,
      [FDK.Constants.TicketConstants.TicketStatus.CLOSED]: true,
      [FDK.Constants.TicketConstants.TicketStatus.BLOCKED]: true,
      [FDK.Constants.TicketConstants.TicketStatus.REJECTED]: false,
    },
  },
  error: null,
  status: FDK.MainAPI.REQUEST_STATUS.UNAVAILABLE,
} as TicketsState;

export const getTickets = createAsyncThunk("tickets/getTickets", async () => {
  return FDK.Agent.TicketsModule.getTicketsList().then((res) => {
    return res.data;
  });
});
export const getTicketConfig = createAsyncThunk("tickets/conifg", async (portId: UUID) => {
  return FDK.MainAPI.API.request<TicketConfig[]>({
    url: `/v1/ticket/configs?port_id${portId}`
  }).then((res) => {
    if (res.data && res.data.length > 0) {
      return res.data[0]
    }
    return null
  })
});

// export const ticketApi = createApi({
//   reducerPath: 'ticketApi',
//   baseQuery: fetchBaseQuery({
//     baseUrl: "v1/ticket",
//   }),
//   endpoints: (builder) => {
//     return {
//       getConfig: builder.query<TicketConfig | null, string>({
//         queryFn: async (portId, { getState }, extraOptions, baseQuery) => {
//           if (portId) {
//             const res = await FDK.MainAPI.API.request<TicketConfig[]>({
//               url: `/v1/ticket/configs?port_id=${portId}`
//             })
//             if (res.data && res.data.length > 0) {
//               return { data: res.data[0] }
//             }
//             return { data: null }
//           }
//           return { data: null }
//         },
//       },
//       )
//     }
//   },
// }
// )

export const getTicketsClosed = createAsyncThunk(
  "tickets/getTicketsClosed",
  async () => {
    return FDK.Agent.TicketsModule.getTicketsClosedList().then((res) => {
      return res.data;
    });
  }
);

export const getTicketById = createAsyncThunk(
  "tickets/getTicket",
  async (id: string, { rejectWithValue }) => {
    return FDK.Agent.TicketsModule.getTicketsById(id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return rejectWithValue(err.status);
      });
  }
);

export const getMessageTicketById = createAsyncThunk(
  "tickets/getTicketMessage",
  async (id: string) => {
    return FDK.Agent.TicketsModule.getTicketsById(id).then((res) => {
      return res.data;
    });
  }
);

// export const generateTicketFileFormat = (ticket: ITicket) => {
//   const newTicket = JSON.parse(JSON.stringify(ticket));
//   return ticket;
// };

export const createTicketMessage = createAsyncThunk(
  "ticketMessage/create",
  async ({ id, message }: { id: UUID; message: Partial<ITicketMessage> }, { dispatch }) => {
    return FDK.Agent.TicketsModule.createTicketMessage(id, message).then((res) => {
      // const newTicket = res.data;
      // dispatch(addTicket(newTicket));
      return res.data as ITicketMessage;
    });
  }
);

export const createTicket = createAsyncThunk(
  "tickets/create",
  async (ticket: ITicket, { dispatch }) => {
    console.log("before create", ticket);

    return FDK.Agent.TicketsModule.createTicket(ticket).then((res) => {
      const newTicket = res.data;
      dispatch(addTicket(newTicket));
      return res.data as ITicket;
    });
  }
);

export const saveTicket = createAsyncThunk(
  "ticket/save",
  async (
    { id, field }: { id: UUID; field: Partial<ITicket> },
    { dispatch }
  ) => {
    console.log("before update", field);
    return FDK.Agent.TicketsModule.updateTicket(id, field).then((res) => {
      if (res.data) {
        console.log("result new ticket", res.data);
      }
      if (res.data.status === "closed") {
        dispatch(removeTicket(res.data));
      }

      return res.data as ITicket;
    });
  }
);

export const slice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    toCreate: (state, { payload }) => {
      state.toCreate = payload;
    },
    resetToCreate: (state) => {
      state.toCreate = null;
    },
    setSelectedTicket: (state, { payload }) => {
      state.selected = payload;
      state.status = FDK.MainAPI.REQUEST_STATUS.UNAVAILABLE;
    },
    setTicketStatus: (state, { payload }) => {
      state.status = payload;
    },
    setCurrentList: (state, { payload }) => {
      state.list = state[payload as "listClosed" | "listOpens"];
      state.currentList = payload;
    },
    addTicket: (state, { payload }) => {
      state.listOpens.push(payload);
    },
    removeTicket: (state, { payload }) => {
      console.log("remove ticket");

      state.listOpens = state.listOpens.filter(
        (ticket) => ticket.id !== payload.id
      );
      state.list.filter((ticket) => ticket.id !== payload.id);
      state.listClosed.filter((ticket) => ticket.id !== payload.id);
      if (state.selected?.id === payload.id) {
        state.selected = null;
      }
      console.log("remove ticket", state);
    },
    resetTickets: () => {
      return { ...initialState };
    },
    updateFilter: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTickets.pending, (state) => {
        state.listLoading = true;
      })
      .addCase(getTickets.fulfilled, (state, { payload }) => {
        state.listLoading = false;
        state.firstLoading = false;
        if (payload) {
          state.listOpens = payload;
          if (state.currentList === "listOpens") state.list = payload;
        } else {
          state.listOpens = [];
        }
      })
      .addCase(getTickets.rejected, (state, { payload }) => {
        state.listLoading = false;
        state.error = payload;
        state.status = FDK.MainAPI.REQUEST_STATUS.ERROR;
      })
      .addCase(createTicket.fulfilled, (state, { payload }) => {
        if (!payload) return;
        state.selected = payload;
        state.loading = false;
        state.status = FDK.MainAPI.REQUEST_STATUS.SUCCESS;
        state.listOpens = state.listOpens.map((t) => {
          if (t.id === payload.id) {
            return payload;
          }
          return t;
        });
      })
      .addCase(createTicketMessage.pending, (state) => {
        state.loading = true;
      })
      .addCase(createTicketMessage.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createTicketMessage.fulfilled, (state, { payload }) => {
        state.loading = false;
        let key = state.list.findIndex((t) => t.id === payload.ticket);
        // update list nb_messages
        if (state.list[key]) {
          if (state.list[key].nb_messages) state.list[key].nb_messages! += 1;
          else state.list[key].nb_messages = 1;
        }
        // update selected nb_messages
        if (state.selected?.id === payload.ticket) {
          if (state.selected!.nb_messages) state.selected!.nb_messages! += 1;
          else state.selected!.nb_messages = 1;
          state.selected!.messages!.push(payload);
        }
      })
      .addCase(createTicket.pending, (state, { payload }) => {
        state.loading = true;
        state.status = FDK.MainAPI.REQUEST_STATUS.PENDING;
      })
      .addCase(saveTicket.fulfilled, (state, { payload }) => {
        state.selected = payload;
        state.loading = false;
        let temp = [...state.listOpens].filter((t) => t.id !== payload.id);
        temp.push(payload);
      })
      .addCase(saveTicket.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getTicketById.fulfilled, (state, { payload }) => {
        state.selected = payload;
      })
      .addCase(getTicketById.rejected, (state, { payload }) => {
        state.error = payload;
        state.status = FDK.MainAPI.REQUEST_STATUS.ERROR;
      })
      .addCase(getMessageTicketById.fulfilled, (state, { payload }) => {
        if (
          state.selected &&
          payload.messages?.length === state.selected.messages?.length
        ) {
          return;
        }

        state.selected = payload;
      })
      .addCase(getTicketsClosed.pending, (state, { payload }) => {
        state.listLoading = true;
      })
      .addCase(getTicketsClosed.fulfilled, (state, { payload }) => {
        state.listLoading = false;
        if (payload) {
          state.listClosed = payload;
          if (state.currentList === "listClosed") state.list = payload;
          console.log("getTicketsClosed", state.currentList);
        } else {
          state.listClosed = [];
        }
      })
      .addMatcher(ticketApi.endpoints.getConfig.matchFulfilled, (state, { payload }) => {
        state.config = payload
      })
  },
});

export default slice.reducer;

export const {
  toCreate,
  resetToCreate,
  setSelectedTicket,
  setTicketStatus,
  addTicket,
  removeTicket,
  resetTickets,
  setCurrentList,
  updateFilter,
} = slice.actions;
