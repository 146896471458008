import classNames from "classnames";

export default function BtnCheckbox(props: {
  size?: string;
  label?: string;
  color?: string;
  selected: boolean;
  iconfile?: { default: any; selected: any };
  children?: any;
  className?: string,
  [key: string]: any;
}) {
  let {
    size = "medium",
    label,
    color = "default",
    selected = false,
    iconfile,
    className
  } = props;
  const btnClass = "px-4 py-2 w-full rounded-md items-center justify-center flex ";
  const textClass = "font-extrabold text-xs text-center";

  const text = selected
    ? `${textClass} text-CALCITE`
    : `${textClass} text-GREY`;
  const btn = selected
    ? `${btnClass} bg-PRIMARY`
    : `${btnClass} bg-CALCITE`;
  return (
    <div className={classNames(className, "flex gap-2 flex-start")}>
      <button {...props} className={btn}>
        <div className="flex items-center justify-center flex-col">
          {iconfile && (
            <img
              alt="ico categ"
              style={!selected ? { filter: "invert(0.5) sepia(1) saturate(0)" } : {}}
              className={"w-4 h-4"}
              src={selected ? iconfile.selected : iconfile.default}
            />
          )}
          {label && <div className={text}>{label}</div>}
          {props.children}
        </div>
      </button>
    </div>
  );
}
