import FDK from "@wattsonelements/front-fdk"
import { TicketConfig } from "../../models/Ticket"
import { apiSlice } from "./api"

export const ticketApi = apiSlice.injectEndpoints({
  endpoints: (builder) => {
    return {
      getConfig: builder.query<TicketConfig | null, string>({
        queryFn: async (portId, { getState }, extraOptions, baseQuery) => {
          if (portId) {
            const res = await FDK.MainAPI.API.request<TicketConfig[]>({
              url: `/v1/ticket/configs?port_id=${portId}`
            })
            if (res.data && res.data.length > 0) {
              return { data: res.data[0] }
            }
            return { data: null }
          }
          return { data: null }
        },
      },
      )
    }
  },
}
)

export const { useGetConfigQuery, useLazyGetConfigQuery } = ticketApi