import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IUserAgent } from "@wattsonelements/front-fdk/dist/models/User.models";

const getUsers = ({ users }: RootState) => users.all;

export const getLocalUserById = createSelector(
  getUsers,
  (_: any, id: string | undefined) => id,
  (users: IUserAgent[], id: string | undefined) => {
    return users.find((u) => u.id === id);
  }
);
